import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import uniq from "lodash.uniq";

import PhotoGrid from "./photo-grid";
import PhotoGridFilter from "./photo-grid-filter";

class ProjectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      group: "all"
    };
  }

  onChangeGroup = group => {
    this.setState({ group });
  };

  render() {
    const { group } = this.state;
    const { data } = this.props;
    const { edges: posts } = data.allKdccmsProjects;

    if (!posts || posts.length === 0) {
      return <h3>Coming soon...</h3>;
    }

    let groups = [];
    posts.map(({ node: post }) => {
      const tags = post.tags.split(",").map(item => item.trim().toLowerCase());
      return groups.push(...tags);
    });
    groups = uniq(groups);
    groups.sort();

    return (
      <Fragment>
        <PhotoGridFilter
          group={group}
          groups={groups}
          onChangeGroup={this.onChangeGroup}
        />

        <PhotoGrid
          photos={posts.map(({ node: post }) => {
            const tags = post.tags
              .split(",")
              .map(item => item.trim().toLowerCase());
            return {
              img: post.bannerImage,
              title: post.title,
              url: `/projects/${post.slug}`,
              groups: tags
            };
          })}
          group={group}
        />
      </Fragment>
    );
  }
}

ProjectList.propTypes = {
  data: PropTypes.shape({
    allKdccmsProjects: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export default () => (
  <StaticQuery
    query={graphql`
      query ProjectListQuery {
        allKdccmsProjects(sort: { order: DESC, fields: [publishDate] }) {
          edges {
            node {
              id
              slug
              title
              tags
              bannerImage {
                childImageSharp {
                  fluid(maxWidth: 403, quality: 87) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <ProjectList data={data} count={count} />}
  />
);
