import React, { Component } from "react";
import Shuffle from "shufflejs";
import { Link } from "gatsby";

/**
 * A grid item for a photo.
 * @param {{ id: number, username: string, src: string, name: string }} props Component props.
 * @return {JSX.Element}
 */
const PhotoItem = ({ img, title, url, groups }) => {
  const groupsText = JSON.stringify(groups);
  return (
    <div
      key={img.src}
      className="col-lg-4 col-sm-6 col-12 photo-item mb-4"
      data-groups={groupsText}
    >
      <div className="aspect aspect--4x3">
        <div className="aspect__inner">
          <Link to={url}>
            <img
              className="photo-item-img mx-auto d-block img-fluid"
              src={img.childImageSharp.fluid.src}
              alt={title}
              title={title}
            />

            <span className="photo-attribution">
              <span>{title}</span>
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

// Create the component which will use Shuffle.
export default class PhotoGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photos: []
    };

    this.element = React.createRef();
    this.sizer = React.createRef();
  }

  /**
   * Fake and API request for a set of images.
   * @return {Promise<Object[]>} A promise which resolves with an array of objects.
   */
  _fetchPhotos() {
    return new Promise(resolve => {
      setTimeout(() => {
        const { photos } = this.props;

        // no need to fetch
        // const items = [];
        // photos.forEach(photo => {
        //   items.push({ src: photo.img });
        // });
        resolve(photos);
      }, 10);
    });
  }

  /**
   * Resolve a promise when all the photos in an array have loaded.
   * @param {Object[]} photos Photos to load.
   * @return {Promise<Object[]>} Loaded images.
   */
  _whenPhotosLoaded(photos) {
    return Promise.all(
      photos.map(
        photo =>
          new Promise(resolve => {
            const image = document.createElement("img");
            image.src = photo.img.childImageSharp.fluid.src;

            if (image.naturalWidth > 0 || image.complete) {
              resolve(photo);
            } else {
              image.onload = () => {
                resolve(photo);
              };
            }
          })
      )
    );
  }

  componentDidMount() {
    // The elements are in the DOM, initialize a shuffle instance.
    this.shuffle = new Shuffle(this.element.current, {
      itemSelector: ".photo-item",
      sizer: this.sizer.current,
      group: this.props.group
    });
    // Kick off the network request and update the state once it returns.
    this._fetchPhotos()
      .then(this._whenPhotosLoaded.bind(this))
      .then(photos => {
        this.setState({ photos });
        setTimeout(() => {
          this.shuffle.update();
        }, 100);
      });
  }

  componentDidUpdate(prevProps) {
    // Notify shuffle to dump the elements it's currently holding and consider
    // all elements matching the `itemSelector` as new.
    if (this.props.group !== prevProps.group) {
      this.shuffle.filter(this.props.group);
    } else {
      this.shuffle.resetItems();
    }
  }

  componentWillUnmount() {
    // Dispose of shuffle when it will be removed from the DOM.
    this.shuffle.destroy();
    this.shuffle = null;
  }

  render() {
    return (
      <div ref={this.element} className="row my-shuffle">
        {this.state.photos.map(image => {
          return <PhotoItem {...image} key={image.url} />;
        })}
        <div ref={this.sizer} className="col-1 photo-grid__sizer"></div>
      </div>
    );
  }
}
