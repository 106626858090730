import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroBanner from "../components/hero-banner";
import ProjectList from "../components/project-list";

export default ({ data }) => (
  <Layout>
    <SEO
      title="Projects"
      keywords={[
        "electrical",
        "Philippines",
        "design",
        "build",
        "system",
        "supply",
        "troubleshoot",
        "repair",
        "equipments",
        "test",
        "manage",
        "bms"
      ]}
    />
    <HeroBanner
      desktopWide={data.bgDesktopWide.childImageSharp.fluid.src}
      desktop={data.bgDesktop.childImageSharp.fluid.src}
      tablet={data.bgTablet.childImageSharp.fluid.src}
      mobile={data.bgMobile.childImageSharp.fluid.src}
      mobileXS={data.bgMobileXS.childImageSharp.fluid.src}
    >
      <h1 className="text-white">Our Projects</h1>
    </HeroBanner>

    <div className="container space-2 space-3--lg">
      <ProjectList />
    </div>
    <hr />
  </Layout>
);

export const query = graphql`
  query {
    bgDesktopWide: file(relativePath: { eq: "projects-bg.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgDesktop: file(relativePath: { eq: "projects-bg.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgTablet: file(relativePath: { eq: "projects-bg.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 992, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgMobile: file(relativePath: { eq: "projects-bg.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgMobileXS: file(relativePath: { eq: "projects-bg.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
