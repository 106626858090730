import React from "react";

const PhotoGridFilter = ({ group: active, groups, onChangeGroup }) => {
  return (
    <ul className="list-inline text-center mb-5">
      <li
        className={
          active === "all"
            ? "list-inline-item photo-grid-item active"
            : "list-inline-item photo-grid-item"
        }
        onClick={() => onChangeGroup("all")}
      >
        All
      </li>
      {groups &&
        groups.map(group => {
          return (
            <li
              key={group}
              className={
                active === group
                  ? "list-inline-item photo-grid-item active"
                  : "list-inline-item photo-grid-item"
              }
              onClick={() => onChangeGroup(group)}
            >
              {group}
            </li>
          );
        })}
    </ul>
  );
};

export default PhotoGridFilter;
